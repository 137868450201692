import React, { useEffect } from 'react';
import { Masonry } from 'masonic';
import styled from 'styled-components';
import { themeVal, rgba, media } from '@devseed-ui/theme-provider';
import { Button } from '@devseed-ui/button';
import { CollecticonBrandGithub } from '@devseed-ui/collecticons';

import {
  SectionHeader,
  SectionHeadline,
  SectionTitle,
  SectionBody,
  sectionCardGroupHeaderGrid,
  SectionCardGroup,
  SectionLead
} from '../../styles/section';
import useMediaAware from '../../utils/media-query-aware-hook';
import { MasonryItemsListElement } from '../../styles/masonry';
import {
  Card,
  CardBody,
  CardHeader,
  CardSuptitle,
  CardTitle
} from '../../components/cards';
import UniversalGridder from '../../styles/universal-gridder';

const RepoLanguage = styled.span`
  color: ${themeVal('color.primary')};
  font-size: 0.875rem;
  margin-left: 0.5rem;
`;

const RepoCards = styled(Card)`
  min-height: 5rem;
`;

const CardLink = styled.a`
  display: block;

  &,
  &:visited {
    color: inherit;
  }

  &:focus ${RepoCards} {
    box-shadow: 0 16px 32px 0 ${rgba(themeVal('color.base'), 0.16)};
    transform: translate(0, 0);
  }

  &:hover {
    opacity: 1;

    ${RepoCards} {
      transform: translate(0, -0.125rem);
      box-shadow: 0 32px 72px 0 ${rgba(themeVal('color.base'), 0.24)};
    }
  }
`;

const SectionCardGroupRepos = styled(SectionCardGroup)`
  .repo-cards,
  &::before,
  &::after {
    grid-row: 3;

    ${media.largeUp`
      grid-row: 2;
    `}
  }

  .repo-actions {
    grid-row: 2;
  }
`;

const SectionCardGroupActions = styled.div`
  grid-column: 1/-1;
`;

const latestItemsRenderFn = ({ data }) => {
  const { title, description, url, lang } = data;

  return (
    <CardLink href={url}>
      <RepoCards>
        <CardHeader>
          <CardTitle>{title}</CardTitle>
          <CardSuptitle>
            Repository <RepoLanguage>{lang}</RepoLanguage>
          </CardSuptitle>
        </CardHeader>
        {description && <CardBody>{description}</CardBody>}
      </RepoCards>
    </CardLink>
  );
};

const FEATURED_REPOS = [
  {
    title: 'Earth Observation API',
    description:
      'eoAPI combines several state-of-the-art projects to create a full Earth Observation API.',
    url: 'https://github.com/developmentseed/eoAPI',
    lang: 'Typescript'
  },
  {
    title: 'TiTiler',
    description:
      'TiTiler is a set of python modules that focus on creating FastAPI application for dynamic tiling.',
    url: 'https://github.com/developmentseed/titiler',
    lang: 'Python'
  },
  {
    title: 'OSM Seed',
    description:
      'A collection of Dockerfiles to run a containerized version of OpenStreetMap',
    url: 'https://github.com/developmentseed/osm-seed',
    lang: 'Shell'
  },
  {
    title: 'Segment Anything Services',
    description: 'Segment Anything encoder and decoder as service',
    url: 'https://github.com/developmentseed/segment-anything-services',
    lang: 'Multiple'
  },
  {
    title: 'Pearl Backend',
    description:
      'PEARL is a landcover mapping platform that uses human in the loop machine learning approach',
    url: 'https://github.com/developmentseed/pearl-backend',
    lang: 'Javascript'
  },
  {
    title: 'Stac React',
    description: 'React hooks to build front-end applications for STAC APIs',
    url: 'https://github.com/developmentseed/stac-react',
    lang: 'Typescript'
  }
];

const SectionRepos = () => {
  const [ref, media] = useMediaAware();

  // In this case our reference is the body, and not a specific element.
  useEffect(() => {
    ref(document.body);
  }, [ref]);

  return (
    <SectionCardGroupRepos id='featured-repositories'>
      <SectionHeader leadLayout='column' className='repo-info'>
        <SectionHeadline>
          <SectionTitle>Featured Repositories</SectionTitle>
        </SectionHeadline>
        <SectionLead>
          It is no accident that open source is at the core of our business
          strategy. With over 500 public GitHub repositories, we firmly believe
          in making things people can share and reuse. By utilizing open
          components, this approach aggregates software investments into a
          collective public good. By adopting open licensing, we provide
          flexibility in tool utilization instead of burdening organizations
          with software license fees. At the heart of it all, we take immense
          pride in our work, always eager to present, share, and learn.
          We&apos;re not just creating software; we&apos;re fostering a
          community. We invite you to use our work, contribute to projects that
          resonate with you, and join us on this open-source journey.
        </SectionLead>
      </SectionHeader>
      <UniversalGridder
        grid={sectionCardGroupHeaderGrid}
        className='repo-actions'
      >
        <SectionCardGroupActions>
          <Button
            variation='primary-outline'
            size='large'
            forwardedAs='a'
            href='https://github.com/developmentseed/'
          >
            <CollecticonBrandGithub /> View our Github homepage
          </Button>
        </SectionCardGroupActions>
      </UniversalGridder>
      <SectionBody className='repo-cards'>
        <Masonry
          items={FEATURED_REPOS}
          overscanBy={Infinity}
          columnGutter={
            media
              ? media.isXsmallDown()
                ? 16
                : media.isLargeDown()
                ? 32
                : 48
              : null
          }
          columnCount={media ? (media.isSmallDown() ? 1 : 2) : null}
          columnWidth={288}
          as={MasonryItemsListElement}
          itemAs='li'
          itemKey={(item) => item.url}
          render={latestItemsRenderFn}
        />
      </SectionBody>
    </SectionCardGroupRepos>
  );
};

export default SectionRepos;
