import React, { useEffect } from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { Button } from '@devseed-ui/button';
import { get } from 'lodash';
import { Masonry } from 'masonic';
import { media } from '@devseed-ui/theme-provider';

import useMediaAware from '../../utils/media-query-aware-hook';

import { ContinuumFold } from '../../styles/fold-continuum';
import {
  FoldInner,
  FoldSuptitle,
  FoldLead,
  FoldBody,
  FoldBlock,
  FoldTitle,
  FoldHeader
} from '../../styles/fold';
import { CardProject } from '../../components/cards';
import { MasonryItemsListElement } from '../../styles/masonry';

const LabsFoldBody = styled.div`
  grid-column: content-start / content-end;

  ${media.mediumUp`
    grid-column: content-3 / content-end;
  `}
`;

const Cta = styled.div`
  grid-column: 1 / -1;
  display: flex;
  justify-content: center;
`;

const projectItemsRenderFn = ({ index, data }) => {
  const { slug } = data;
  const { title, media } = data.frontmatter;
  const cardImage = get(media, 'card.url.childImageSharp.fluid');

  const { client } = data.frontmatter;
  return (
    <CardProject
      title={title}
      slug={slug}
      cardImage={cardImage}
      client={client}
      index={index + 1}
      skin='dark'
    />
  );
};

export default function SectionLabs() {
  const data = useStaticQuery(graphql`
    query LabsProjects {
      allProject(
        filter: {
          published: { eq: true }
          frontmatter: { topics: { in: "Labs" } }
        }
        limit: 4
        sort: { fields: date, order: DESC }
      ) {
        nodes {
          id
          frontmatter {
            title
            client {
              name
              url
            }
            media {
              ...CardImageProjectFluid
            }
          }
          slug
        }
      }
    }
  `);

  const projectList = data.allProject.nodes;

  const [ref, media] = useMediaAware();

  // In this case our reference is the body, and not a specific element.
  useEffect(() => {
    ref(document.body);
  }, [ref]);

  if (!projectList.length) return null;

  return (
    <ContinuumFold id='labs'>
      <FoldInner>
        <FoldHeader>
          <FoldSuptitle>Devseed Labs</FoldSuptitle>
          <FoldTitle>
            We drive innovation forward through DevSeed Labs
          </FoldTitle>
          <FoldLead>
            <p>
              Development Seed hires some of the smartest, most passionate
              people eager to solve the planet&apos;s toughest problems, usually
              collaborating with mission-driven partners.
            </p>
            <p>
              While we&apos;re compensated for most tasks, we also pursue
              self-funded projects that align with our values, such as
              maintaining open-source tools, staying updated with technological
              advancements, and creating solutions for environmental and
              humanitarian needs.
            </p>
            <p>
              Our Labs feature both experimental and established open-source
              tools. We advocate for transparent development and welcome
              collaborations. If you have earth data-related challenges, we are
              always looking for compelling problem statements and real, hard
              problems to solve!
            </p>
          </FoldLead>
        </FoldHeader>
        <FoldBody>
          <FoldBlock>
            <LabsFoldBody>
              <Masonry
                items={projectList}
                overscanBy={Infinity}
                columnGutter={
                  media
                    ? media.isXsmallDown()
                      ? 16
                      : media.isLargeDown()
                      ? 32
                      : 48
                    : null
                }
                columnCount={media ? (media.isSmallDown() ? 1 : 2) : null}
                columnWidth={288}
                as={MasonryItemsListElement}
                itemAs='li'
                itemKey={(item) => item.id}
                render={projectItemsRenderFn}
              />
            </LabsFoldBody>
          </FoldBlock>
          <Cta>
            <Button
              variation='primary-fill'
              size='large'
              forwardedAs={Link}
              to='/projects/topics/labs'
            >
              View Lab Projects
            </Button>
          </Cta>
        </FoldBody>
      </FoldInner>
    </ContinuumFold>
  );
}
