import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Button } from '@devseed-ui/button';
import { CollecticonBrandGithub } from '@devseed-ui/collecticons';

import Layout from '../../components/layout';
import Diptych from '../../components/diptych';
import {
  Inpage,
  InpageHeader,
  InpageHeaderInner,
  InpageHeadline,
  InpageTitle,
  InpageLead,
  InpageBody
} from '../../styles/inpage';
import { CallToAction, CTAActions } from '../../components/call-to-action';

import communityHeadImg from '../../media/layout/community/community-head.png';
import satsummitEventImg from '../../media/layout/community/satsummit.jpg';

import SectionEvents from './_section-events';
import SectionLabs from './_section-labs';
import SectionRepos from './_section-repos';

const Community = ({ location }) => {
  return (
    <Layout location={location} title='Community'>
      <Inpage as='section'>
        <InpageHeader>
          <InpageHeaderInner>
            <InpageHeadline>
              <InpageTitle>Open by default</InpageTitle>
            </InpageHeadline>
            <InpageLead>
              <p>
                Development Seed is “open by default.” We work with open-source
                technology to make the tools we build and use more broadly
                accessible.
              </p>
            </InpageLead>
          </InpageHeaderInner>
        </InpageHeader>
        <InpageBody>
          <Diptych
            layout='beta'
            suptitle='Open Source'
            title='We are contributors, developers, maintainers, and users of open-source tools.'
            src={communityHeadImg}
            alt='Grid overlaying a satellite image'
          >
            <p>
              We collaborate with organizations and communities eager to address
              some of the world&apos;s most challenging problems. Take a look at
              some of our repositories to get a sense of what we do. Want to
              make a contribution? Make a pull request!
            </p>
          </Diptych>

          <SectionRepos />

          <SectionEvents />

          <Diptych
            layout='beta'
            id='satsummit'
            suptitle='Community Building'
            title='SatSummit'
            src={satsummitEventImg}
            alt='Grid overlaying a satellite image'
          >
            <>
              <p>
                SatSummit convenes leaders in the satellite industry and experts
                in global development for two days of presentations and in-depth
                conversations on solving the world&apos;s most critical
                development challenges with satellite data.
              </p>
              <Button
                forwardedAs='a'
                href='https://satsummit.io/'
                variation='primary-fill'
                size='large'
              >
                Go to the SatSummit website
              </Button>
            </>
          </Diptych>

          <SectionLabs />

          <CallToAction
            title='Love open source? Join our team!'
            suptitle='Join us'
          >
            <p>
              We&apos;re looking for mission-driven individuals eager to address
              some of the world&apos;s most challenging problems.
            </p>
            <CTAActions>
              <Button
                variation='primary-fill'
                size='large'
                forwardedAs={Link}
                to='/careers'
              >
                See open positions
              </Button>
              <Button
                variation='primary-outline'
                size='large'
                forwardedAs='a'
                href='https://github.com/developmentseed/'
              >
                <CollecticonBrandGithub /> Make a contribution
              </Button>
            </CTAActions>
          </CallToAction>
        </InpageBody>
      </Inpage>
    </Layout>
  );
};

export default Community;

Community.propTypes = {
  location: PropTypes.object
};
